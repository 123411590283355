@font-face {
  font-family: 'Impact';
  src: local('Impact'), url('./fonts/impact.ttf') format('truetype');
}

@font-face {
  font-family: 'Comic Sans MS';
  src: local('Comic Sans MS'), url('./fonts/COMIC.TTF') format('truetype');
}

.font-impact {
  font-family: 'Impact', sans-serif;
}

.font-comic-sans {
  font-family: 'Comic Sans MS', sans-serif;
}